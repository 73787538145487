import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDhdJh4x74PvHI6URM-pr0-eLulpf2RYFU",
  authDomain: "ts-octowit-dev.firebaseapp.com",
  projectId: "ts-octowit-dev",
  storageBucket: "ts-octowit-dev.appspot.com",
  messagingSenderId: "708727287067",
  appId: "1:708727287067:web:0517a378de74693498b7be",
  measurementId: "G-1XZ2YDFQEB",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const microsoftProvider = new OAuthProvider("microsoft.com");
const auth = getAuth(app);


export { auth, googleProvider, facebookProvider, microsoftProvider };
