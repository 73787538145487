// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_ReactTransliterate__ccxgX {
  background-clip: padding-box;
  background-color: #000;
  /* border: 1px solid rgba(0, 0, 0, 0.15); */
  /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); */
  display: block;
  font-size: 12px;
  list-style: none;
  padding: 0px;
  text-align: left;
  z-index: 20000;
  width: 100%;
}

.styles_ReactTransliterate__ccxgX > li {
  cursor: pointer;
  padding: 10px;
  min-width: 100px;
}

.styles_Active__pskkk {
  background-color: #02A0FC;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/third-party/react-transliterate/styles.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,sBAAsB;EACtB,2CAA2C;EAC3C,iDAAiD;EACjD,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,WAAW;AACb;;AAEA;EACE,eAAe;EACf,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb","sourcesContent":[".ReactTransliterate {\n  background-clip: padding-box;\n  background-color: #000;\n  /* border: 1px solid rgba(0, 0, 0, 0.15); */\n  /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); */\n  display: block;\n  font-size: 12px;\n  list-style: none;\n  padding: 0px;\n  text-align: left;\n  z-index: 20000;\n  width: 100%;\n}\n\n.ReactTransliterate > li {\n  cursor: pointer;\n  padding: 10px;\n  min-width: 100px;\n}\n\n.Active {\n  background-color: #02A0FC;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ReactTransliterate": `styles_ReactTransliterate__ccxgX`,
	"Active": `styles_Active__pskkk`
};
export default ___CSS_LOADER_EXPORT___;
